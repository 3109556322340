import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
  path: "",
  component: () => import_latest_version_module(),
  children: [
    {
      path: "/admin/add-evection",
      name: "admin-add-evection",
      component: () => import(/* webpackChunkName: "page-admin-office-add-evection" */ `./1.0.4.2/index.vue`),
      meta: {
        lastMenu: "adminMyApply",
        remove_alive: true,
      },
    },
  ],
};
