import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/evection-add',
      name: 'admin-evection-add',
      component: () => import(/* webpackChunkName: "page-admin-company-management-evectionManagement-common-evectionAdd" */ `./1.0.1/index.vue`),
      meta: {
        lastMenu: 'evectionPolicy',
        remove_alive: true,
      },
    },
  ],
};
