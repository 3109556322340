import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/train-search',
            name: 'admin-train-search',
            component: () => import(/* webpackChunkName: "page-admin-travel-book-train-search" */ `./1.1.2/index.vue`),
            meta: {
                lastMenu: 'travelOrder',
                data_for_back_layout: {
                    layoutType: 'frontLayoutType'
                }
            },
        },
    ],
};