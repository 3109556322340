import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/cost-center-list',
      name: 'admin-cost-center-list',
      component: () => import(/* webpackChunkName: "page-admin-company-management-cost-center" */ `./index.vue`),
      meta: {
        lastMenu: 'cost-center-list'
      },
    }
  ],
};
