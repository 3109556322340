import import_latest_version_module from '@/component/front-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/hotel-reserve-result',
            name: 'admin-hotel-reserve-result',
            component: () => import(/* webpackChunkName: "page-admin-travel-book-hotel-hotel-reserve-result" */ `./1.0.0/index.vue`),
            meta: {
                lastMenu: 'hotelSearch'
            },
        },
    ],
};
