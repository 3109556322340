//
//
//
//
//
//
//
//
//

//超出指定行数自动隐藏文字
export default {
  name: "Ellipsis",
  install(Vue){
    Vue.component('ellipsis', this)
  },
  components: {},
  props:{
    row: {
      type: Number,
      default: 1
    },
    hoverTip:{
      type: Boolean,
      default: false
    },
    content:{
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {}
}
