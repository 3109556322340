import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/my-information',
            name: 'admin-my-information',
            component: () => import(/* webpackChunkName: "page-admin-personal-center-my-information" */ `./1.0.2/index.vue`),
            meta: {
                lastMenu: 'information',
                hideTab: true,
            },
        },
    ],
};
