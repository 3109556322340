import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import Router from "vue-router";

//允许进行全局query参数的路由名称
const route_name_list = [
    // `admin-global-query-test-page-001`,
    // `admin-global-query-test-page-002`,
    // `admin-global-query-test-page-003`,
    // `*`,
    `admin-train-search`,
    `admin-hotel-search`,
    `admin-flight-search`,
    `admin-hotel-detail`,
    `admin-travel-reserve`,
    `admin-flight-reserve`,
    `admin-travel-grabbing`,
    `admin-hotel-reserve`,
    `admin-payment`,
    `admin-train-grabbing`,
    `admin-train-reserve`,
    `admin-holding-seat`,
];

//全局query参数名称
const query_name_list = [
    `aaa`,
    `bbb`,
    `evectionNo`,
    `evectionType`,
    `canShowSendMsg`,
    `evectionRequired`,
    `workTravelType`,
    `applyId`,
    `journeyType`,
];

const next = function ({to, from, next}) {
    console.warn(`该方法会导致每一次跳转，会进入beforeEach两次，建议不要使用！`);

    //用例
    //1)有多个参数时，直接刷新页面
    //2)【000-000】 ===> 【001-002】 ，expect:page-002?aaa=111&bbb=222
    //3)【000-000】 ===> 【001-004】 ，expect:page-004
    //4)【000-000】 ===> 【003-004】 ===》 【002-001】，expect:page-001?aaa=666&bbb=1666
    //5)【000-000】 ===> 【003-004】 ===》 【002-001】，expect:page-001?aaa=666&bbb=1666
    //6)【002-004】 ===> 【000-000】 ，expect:page-001?aaa=111&bbb=222&ccc=333

    const get_query_data = function (pRoute) {
        const query_list = _.chain(query_name_list)
            .map(function (m) {
                const name = m;
                const value = pRoute.query[name];

                const o = {};
                o.name = name;
                o.value = value;
                return o;
            })
            .filter(function (m) {
                return m.value;
            })
            .value()
        ;

        const query_object = _.chain(query_list)
            .groupBy(function (m) {
                return m.name;
            })
            .mapObject(function (property_value, property_name) {
                const list = property_value;
                let r = ``;
                if (!_.isArray(list)) return r;
                if (list.length <= 0) return r;
                const first = list[0];
                r = first.value;
                return r;
            })
            .value()
        ;

        //query_name_list下，所有query都有值
        const flag_all_query_has_value = query_list.length === query_name_list.length;

        return {
            query_list,
            query_object,
            flag_all_query_has_value,
        };
    };

    const from_query_data = get_query_data(from);
    const from_query_list = from_query_data.query_list;
    const from_query_object = from_query_data.query_object;

    const to_query_data = get_query_data(to);
    const to_query_list = to_query_data.query_list;
    const to_query_object = to_query_data.query_object;
    // const to_flag_all_query_has_value = to_query_data.flag_all_query_has_value;//query_name_list下，所有query都有值

    const to_name = to.name;

    //query_object_change改变标志
    const flag_query_object_change = JSON.stringify(from_query_object) === JSON.stringify(to_query_object);

    // if (!_.contains(route_name_list, `*`)) {
    // if (!_.contains(route_name_list, to_name)) {
    if (!_.contains(route_name_list, to_name) && !_.contains(route_name_list, `*`)) {
        next();
    } else if (to.params.flag_to_next) {
        next();
    } else if (!flag_query_object_change) {
        const query = extend({}, from_query_object, to.query);
        next({
            name: to_name,
            query: query,
            params: {
                flag_to_next: true,
            },
        });
    } else {
        next();
    }
};

const init = function () {
    const originalPush = Router.prototype.push;
    Router.prototype.push = function push(location) {
        if (typeof location !== `object`) {
            return originalPush.call(this, location);
        }

        const currentRoute = this.currentRoute;
        const from_query = currentRoute.query;

        const to_name = location.name || ``;
        if (!_.contains(route_name_list, to_name) && !_.contains(route_name_list, `*`)) {
            return originalPush.call(this, location);
        }

        const to_query_000 = location.query || {};
        const to_query_001 = _.chain(from_query)
            .pairs()
            .map(function (m) {
                const property_name = m[0];
                const property_value = m[1];
                return {
                    property_name,
                    property_value,
                };
            })
            .filter(function (m) {
                const property_name = m.property_name;
                return _.contains(query_name_list, property_name);
            })
            .groupBy(function (m) {
                return m.property_name;
            })
            .mapObject(function (property_value, property_name) {
                const list = property_value;
                const first = _.first(list);
                const __property_value = first.property_value;
                return __property_value;
            })
            .value()
        ;
        const to_query_002 = extend({}, to_query_001, to_query_000);

        // TypeError: Cannot assign to read only property 'query' of object '#<Object>'
        // location.query = to_query_002;
        // return originalPush.call(this, location);

        const __location = extend(true, {}, location);
        __location.query = to_query_002;
        return originalPush.call(this, __location);
    };

    const originalReplace = Router.prototype.replace;
    Router.prototype.replace = function replace(location) {
        if (typeof location !== `object`) {
            return originalReplace.call(this, location);
        }

        const currentRoute = this.currentRoute;
        const from_query = currentRoute.query;

        const to_name = location.name || ``;
        if (!_.contains(route_name_list, to_name) && !_.contains(route_name_list, `*`)) {
            return originalPush.call(this, location);
        }

        const to_query_000 = location.query || {};
        const to_query_001 = _.chain(from_query)
            .pairs()
            .map(function (m) {
                const property_name = m[0];
                const property_value = m[1];
                return {
                    property_name,
                    property_value,
                };
            })
            .filter(function (m) {
                const property_name = m.property_name;
                return _.contains(query_name_list, property_name);
            })
            .groupBy(function (m) {
                return m.property_name;
            })
            .mapObject(function (property_value, property_name) {
                const list = property_value;
                const first = _.first(list);
                const __property_value = first.property_value;
                return __property_value;
            })
            .value()
        ;
        const to_query_002 = extend({}, to_query_001, to_query_000);

        // TypeError: Cannot assign to read only property 'query' of object '#<Object>'
        // location.query = to_query_002;
        // return originalReplace.call(this, location);

        const __location = extend(true, {}, location);
        __location.query = to_query_002;
        return originalReplace.call(this, __location);
    };
};

export default {
    init,
    next,
    route_name_list,
    query_name_list,
}

// 参考
// https://segmentfault.com/a/1190000011042794
