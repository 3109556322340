import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/online-repayment',
            name: 'admin-online-repayment',
            component: () => import(/* webpackChunkName: "page-admin-finance-settlement-management-online-repayment" */ `./index.vue`),
            meta: {
                lastMenu: 'settlementManagement',
                hideTab: true,
            },
        },
    ],
};