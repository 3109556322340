const init_baidu_tongji = function () {
    if (window.appConfig.environment !== `release`) return;
    var _hmt = _hmt || [];
    (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?50365b14c3928cc0b9c2ba8b72cf0435";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    })();
};

export default init_baidu_tongji;
