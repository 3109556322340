import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '',
            component: () => import(`./1.2.1/index.vue`),
            meta: {
                lastMenu: 'travelOrder',
                data_for_back_layout: {
                    layoutType: 'frontLayoutType'
                },
            },
            children: [
                {
                    path: '/admin/travel-book-home',
                    name: 'admin-travel-book-home',
                    redirect: { name: 'admin-travel-book-home-flight' }
                },
                {
                    path: '/admin/travel-book-home/flight',
                    name: 'admin-travel-book-home-flight',
                    component: () => import(/* webpackChunkName: "page-admin-travel-book-home" */ `@/page/admin/travel-book/home/component/flight-tab/1.1.2/index.vue`),
                    meta: {
                        lastMenu: 'travelOrder',
                        data_for_back_layout: {
                            layoutType: 'frontLayoutType'
                        },
                        tab: '1'
                    },
                },
                {
                    path: '/admin/travel-book-home/train',
                    name: 'admin-travel-book-home-train',
                    component: () => import(/* webpackChunkName: "page-admin-travel-book-home" */ `@/page/admin/travel-book/home/component/train-tab/1.1.2/index.vue`),
                    meta: {
                        lastMenu: 'travelOrder',
                        data_for_back_layout: {
                            layoutType: 'frontLayoutType'
                        },
                        tab: '2'
                    },
                },
                {
                    path: '/admin/travel-book-home/hotel',
                    name: 'admin-travel-book-home-hotel',
                    component: () => import(/* webpackChunkName: "page-admin-travel-book-home" */ `@/page/admin/travel-book/home/component/hotel-tab/1.1.3/index.vue`),
                    meta: {
                        lastMenu: 'travelOrder',
                        data_for_back_layout: {
                            layoutType: 'frontLayoutType'
                        },
                        tab: '3'
                    },
                },
                {
                    path: '/admin/travel-book-home/car',
                    name: 'admin-travel-book-home-car',
                    component: () => import(/* webpackChunkName: "page-admin-travel-book-home" */ `@/page/admin/travel-book/home/component/car-tab/index.vue`),
                    meta: {
                        lastMenu: 'travelOrder',
                        data_for_back_layout: {
                            layoutType: 'frontLayoutType'
                        },
                        tab: '4'
                    },
                },
            ]
        },
    ],
};
