import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/finance/balance-recharge',
            name: 'admin-finance-balance-recharge',
            component: () => import(/* webpackChunkName: "page-admin-finance-balance-balance-recharge" */ `./index.vue`),
            meta: {
                lastMenu: 'adminFinanceBalance',
                hideTab: true,
            },
        }
    ],
};
