import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/edit-hotel-standards',
            name: 'admin-edit-hotel-standards',
            component: () => import(/* webpackChunkName: "page-admin-company-management-evectionManagement-hotelTravelStandards-editHotelStandards" */ `./1.0.3/index.vue`),
            meta: {
                lastMenu: 'hotelTravelStandard',
                remove_alive: true
            },
        },
    ],
};
