import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/change-password',
            name: 'admin-change-password',
            component: () => import(/* webpackChunkName: "page-admin-personal-center-change-password" */ `./index.vue`),
            meta: {
                lastMenu: 'password',
                hideTab: true,
                remove_alive: true,
            },
        },
    ],
};