import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/my-apply-manage/apply-approve-exceed',
            name: 'admin-my-apply-manage-apply-approve-exceed',
            component: () => import(/* webpackChunkName: "page-admin-office-my-apply-exceed-applyApproveExceed" */ `./index.vue`),
            meta: {
                lastMenu: ''
            },
        }
    ],
};
