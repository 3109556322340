import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/evection-train-policy-edit',
            name: 'admin-evection-train-policy-edit',
            component: () => import(/* webpackChunkName: "page-admin-company-management-evectionManagement-evectionTrainPolicyEdit" */ `./1.0.1/index.vue`),
            meta: {
                lastMenu: 'evectionTrainPolicy'
            },
        },
    ],
};
