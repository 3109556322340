import import_latest_version_module from './import_latest_version_module';

export default {
    path: '/login',
    name: 'login',
    component: () => import_latest_version_module(),
    meta: {
        enable_check_token: false,
    },
};

