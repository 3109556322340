import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/order-center/flight-change-detail',
            name: 'admin-order-center-flight-change-detail',
            component: () => import(/* webpackChunkName: "page-admin-order-center-flight-change-detail" */ '@/page/admin/myOrder/component/flight-change-detail/1.0.2/index.vue'),
            meta: {
                lastMenu: 'orderCenterFlightChangeList',
                hideTab: true,
                data_for_back_layout: {
                    layoutType: 'orderCenterType',
                    lastMenu: 'orderCenterFlightChangeList',
                }
            }
        }
    ]
}
