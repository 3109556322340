export default {
    path: '',
    component: () => import(`@/component/test-layout/index.vue`),
    children: [
        {
            path: '/admin/test-list',
            name: 'admin-test-list',
            component: () => import(/* webpackChunkName: "page-demo-payment-application-list" */ `./index.vue`),
            meta: {
                skip_check_token: true,
                lastMenu: 'travelOrder',
            },
        },
    ],
};
