import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/my-order-flight-refundForm',
            name: 'admin-my-order-flight-refundForm',
            component: () => import(/* webpackChunkName: "page-admin-myOrder-flightOrder-refundForm" */ `./index.vue`),
            meta: {
                lastMenu: 'adminMyOrderFlightRefundForm',
                showPrint: true,
            },
        }
    ],
};
