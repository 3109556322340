import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/my-order-train',
            name: 'admin-my-order-train',
            component: () => import(/* webpackChunkName: "page-admin-myOrder-trainOrder-trainOrderList" */ `./1.0.3/index.vue`),
            meta: {
                lastMenu: 'adminMyOrderTrainList',
                showPrint: true,
            },
        }
    ],
};
