import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/train-account-check',
            name: 'admin-train-account-check',
            component: () => import(/* webpackChunkName: "page-admin-personal-center-trainAccountCheck" */ `./1.0.1/index.vue`),
            meta: {
                lastMenu: 'information',
                hideTab: true,
                data_for_back_layout: {
                    layoutType: 'frontLayoutType'
                }
            },
        },
    ],
};
