import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/hotel-order-refund',
            name: 'admin-hotel-order-refund',
            component: () => import(/* webpackChunkName: "page-admin-myOrder-hotel-hotel-order-refund" */ `./1.0.2/index.vue`),
            meta: {
                lastMenu: 'adminMyOrderHotel',
                hideTab: true,
                hotelMeta: '退订申请'
            },
        },
    ],
};
