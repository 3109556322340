import Q from 'q'
import router from '@/router'
import * as dd from "dingtalk-jsapi";

const redirect_to_login_page = function (pParameter) {
    if (dd.env.platform !== "notInDingTalk") {
        dd.getStorage({
            key: 'corpId',
            success: (res) => {
                router.replace({
                    name: `dd-login`,
                    query: {
                        corpId: res,
                    }
                });
            },
            fail: () => {},
            complete: () => {},
        });
        return;
    }
    router.replace({
        name: `login`,
    });
};

export default redirect_to_login_page;
