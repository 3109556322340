import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/test',
      name: 'admin-test',
      component: () => import(/* webpackChunkName: "page-admin-default-test" */ `./index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `测试`,
        },
        entity_list_for_breadcrumb: [
          {name: `基础功能`},
          {name: `测试`},
        ],
        lastMenu: 'companyData'
      },
    },
  ],
};
