import import_latest_version_module from '@/component/front-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/hotel-detail',
            name: 'admin-hotel-detail',
            component: () => import(/* webpackChunkName: "page-admin-travel-book-hotel-hotel-detail" */ `./1.1.5/index.vue`),
            meta: {
                lastMenu: 'travelOrder'
            },
        },
    ],
};
