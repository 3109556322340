import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/order-center/car-order-details',
            name: 'admin-order-center-car-order-details',
            component: () => import(/* webpackChunkName: "page-admin-order-center-car-order-details" */ `@/page/admin/myOrder/car/carOrderDetail/1.0.1/index.vue`),
            meta: {
                lastMenu: 'orderCenterCarOrderList',
                hideTab: true,
                data_for_back_layout: {
                    layoutType: 'orderCenterType',
                    lastMenu: 'orderCenterCarOrderList',
                }
            },
        }
    ],
};

