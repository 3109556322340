import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/all-report',
            name: 'admin-all-report',
            component: () => import(/* webpackChunkName: "page-admin-finance-report-allReport" */ `./1.0.1/index.vue`),
            meta: {
                lastMenu: 'allReportDetail',
            },
        }
    ],
};