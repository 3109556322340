import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/finance/credit/have-bill-detail',
            name: 'admin-finance-credit-have-bill-detail',
            component: () => import(/* webpackChunkName: "page-admin-finance-credit-haveBill-detail" */ `./1.0.3/index.vue`),
            meta: {
                lastMenu: 'adminFinanceCreditHaveBill'
            },
        }
    ],
};
