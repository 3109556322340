import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';
import join from 'nllib/src/uri/join';
import get_uuid from 'nllib/src/common/get_uuid';
import wangyiqiyu from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index.js'

import store from './lib/store';
import update_tips_processor from './lib/common-service/update-tips-processor';

export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    created() {
    },
    mounted() {
        const __this = this;

        const init_store_state_app_config = function () {
            const appConfig = global.appConfig;
            if (!appConfig) return;

            store.commit(`init_app_config`, appConfig);
        };

        const init_favicon = function () {
            const appConfig = global.appConfig;
            if (!appConfig) return;
            const environment = appConfig.environment;
            if (environment !== `local`) {
                const VUE_APP_vue_router_history_public_path = process.env.VUE_APP_vue_router_history_public_path;
                const uuid = get_uuid();
                const href = join(VUE_APP_vue_router_history_public_path, `/favicon.ico?uuid=${uuid}`);

                const link = document.createElement(`link`);
                link.rel = `icon`;
                link.href = href;
                document.querySelector(`head`).appendChild(link);
            }
        };

        const init_head_title = function () {
            const appConfig = global.appConfig;
            if (!appConfig) return;
            const name = appConfig.name;
            document.querySelector(`head title`).innerHTML = name;
        };

        const init_update_tips_processor = function () {
            update_tips_processor.init();
        };

        const init_wangyiqiyu = function() {
            wangyiqiyu.init({
                type: '002',
                canDrag: false
            })
        };

        const setDefer = function () {
            let userInfoDefer = {};
            userInfoDefer.promise = new Promise((resolve,reject)=>{
                userInfoDefer.resolve = resolve;
                userInfoDefer.reject = reject;
            });

            let workflowDefer = {};
            workflowDefer.promise = new Promise((resolve,reject)=>{
                workflowDefer.resolve = resolve;
                workflowDefer.reject = reject;
            });

            let policyDefer = {};
            policyDefer.promise = new Promise((resolve,reject)=>{
                policyDefer.resolve = resolve;
                policyDefer.reject = reject;
            });

            __this.$store.commit('setUserInfoDefer', userInfoDefer);
            __this.$store.commit('setWorkflowDefer', workflowDefer);
            __this.$store.commit('setPolicyDefer', policyDefer);
        }

        init_store_state_app_config();
        init_favicon();
        init_head_title();
        init_update_tips_processor();
        init_wangyiqiyu();
        setDefer();
    },
}
