import import_latest_version_module from '@/component/front-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/train-reserve-result',
            name: 'admin-train-reserve-result',
            component: () => import(/* webpackChunkName: "page-admin-travel-book-train-train-reserve-result" */ `./1.0.0/index.vue`),
            meta: {
                lastMenu: 'flightSearch'
            },
        },
    ],
};
