import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/staff-list',
            name: 'admin-staff-list',
            component: () => import(/* webpackChunkName: "page-admin-company-management-staff-management" */ `./1.0.2/index.vue`),
            meta: {
                lastMenu: 'staffList',
            },
        },
    ],
};
