import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/approval-process-management',
      name: 'admin-approval-process-management',
      component: () => import(/* webpackChunkName: "page-admin-company-management-approval-process-approval-process-management" */ `./0.0.7/index.vue`),
      meta: {
        lastMenu: 'approval-process-management'
      },
    }
  ],
};
