import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/personal-hotel-submit-order',
            name: 'admin-personal-hotel-submit-order',
            component: () => import(/* webpackChunkName: "page-admin-travel-book-hotel-hotel-submit-order" */ `./1.0.0/index.vue`),
            meta: {
                lastMenu: 'travelOrder'
            },
        },
    ],
};
