import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/external-customer-list',
      name: 'admin-external-customer-list',
      component: () => import(/* webpackChunkName: "page-admin-company-management-external-customer" */ `./1.0.2/index.vue`),
      meta: {
        lastMenu: 'external-customer-list'
      },
    }
  ],
};
