import import_latest_version_module from '@/component/front-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/flight-reserve',
            name: 'admin-flight-reserve',
            component: () => import(/* webpackChunkName: "page-admin-travel-book-flight-flight-reserve" */ `./1.0.5/index.vue`),
            meta: {
                lastMenu: 'flightSearch'
            },
        },
    ],
};
