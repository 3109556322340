import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/hotel-city-level-detail',
            name: 'admin-hotel-city-level-detail',
            component: () => import(/* webpackChunkName: "page-admin-company-management-evectionManagement-hotelCityLevelDetail" */ `./2.0.0/index.vue`),
            meta: {
                lastMenu: 'hotelTravelStandard'
            },
        },
    ],
};
