import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/my-apply-manage/have-approve',
            name: 'admin-my-apply-manage-have-approve',
            component: () => import(/* webpackChunkName: "page-admin-office-my-apply-approve-haveApprove" */ `./1.0.1/index.vue`),
            meta: {
                lastMenu: 'adminHaveApprove'
            },
        }
    ],
};
