//
//
//
//
//
//
//
//
//

export default {
  install(Vue) {
    Vue.component('Tip', this)
  },
  name: "Tip",
  components: {},
  props:{
    isDark:{
      type: Boolean,
      default: false
    },
    content:{
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {}
}
