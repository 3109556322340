import Vue from 'vue'
import Vuex from 'vuex'
import Q from 'q'
import moment from 'moment'

import * as getters from './getter'

import get_consumer_menu_getUserMenu from '@/lib/common-service/get_consumer_menu_getUserMenu'

const api_system_getCurrentUser = require(`@/lib/data-service/system/api_system_getCurrentUser`);
const api_system_getGlobalData = require(`@/lib/data-service/system/api_system_getGlobalData`);
const api_system_get_server_config = require(`@/lib/data-service/system/api_system_get_server_config`);

let __store;

let promise_init_global_data;
let promise_init_current_user;
let promise_init_server_config;

const init = function () {
    Vue.use(Vuex);

    __store = new Vuex.Store({
        state: {
            last_refresh_time: moment().format(`YYYY-MM-DD HH:mm:ss`),
            app_config: {},
            global_data: {},
            current_user: {},
            data_for_left_menu: {
                is_open: true,
            },
            data_for_history_tag: {
                tag_list: [],
            },
            data_for_breadcrumb: {
                entity_list: [],
            },
            data_for_theme: {
                type: ``,
                logo_url: ``,
            },
            server_phone: '400-626-8448',
            oriNavList: [], // 原始权限菜单备份
            navList: [], // 权限菜单

            userInfoDefer: {}, // 用户信息获取异步
            userInfo: {}, // 用户信息

            policyDefer: {}, // 出差政策获取异步
            policy: {}, // 出差政策

            workflowDefer: {},
            workflow: '2', // 审批流类型 1 新， 2旧
            // 审批流相关
            nodeMap: new Map(),
            isEdit: null,
            selectedNode: {},
            selectFormItem: null,
            design:{}, // 当前审批流整体配置
            runningList: [],
            noTakeList: [],
            endList: [],
            diagramMode: 'design',
            // 审批流相关-end

            btnList: [], // 权限按钮列表  eg: ['getBudgetDetailButton'],
        },
        getters,
        mutations: {
            update_last_refresh_time(state, pData) {
                console.log(pData);
                state.last_refresh_time = pData;
            },

            init_app_config(state, pData) {
                state.app_config = pData;
            },

            init_global_data(state) {
                promise_init_global_data = Q.when()
                    .then(function () {
                        return api_system_getGlobalData();
                    })
                    .then(function (data) {
                        state.global_data = data.entity;
                        global.global_data = data.entity;
                    })
                    .catch(function (ex) {
                        console.error(ex);
                        if (ex) {
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                        return Q.reject(ex);
                    })
                ;

            },

            init_current_user(state) {
                promise_init_current_user = Q.when()
                    .then(function () {
                        return api_system_getCurrentUser();
                    })
                    .then(function (data) {
                        state.current_user = data.entity;
                        global.current_user = data.entity;
                    })
                    .catch(function (ex) {
                        console.error(ex);
                        if (ex) {
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                        return Q.reject(ex);
                    })
                ;

            },

            init_server_config(state) {
                promise_init_server_config = Q.when()
                    .then(function () {
                        return api_system_get_server_config();
                    })
                    .then(function (data) {
                        state.server_config = data.entity;
                        global.server_config = data.entity;
                    })
                    .catch(function (ex) {
                        console.error(ex);
                        if (ex) {
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                        return Q.reject(ex);
                    })
                ;

            },

            init_data_for_theme(state) {
                const appConfig = global.appConfig;
                const data_for_theme = appConfig.data_for_theme;
                const type = data_for_theme.type;
                state.data_for_theme.type = type;

                if (type === `haolv`) {
                    // state.data_for_theme.logo_url = `https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2021/03/18/6.png`;
                    state.data_for_theme.logo_url = `https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/20/LOGO.png`;
                    // state.data_for_theme.logo_url = `https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/20/LOGO.png?x-oss-process=style/quancheng-default`;
                }

                if (type === `quancheng`) {
                    state.data_for_theme.logo_url = `//haolv-enterprise-app-static.oss-cn-shenzhen.aliyuncs.com/2020/09/24/logo-quancheng.png?x-oss-process=style/haolv-default`;
                }
            },
            setOriNavList(state, oriNavList) {
                state.oriNavList = oriNavList
            },
            setNavList(state, navList) {
                state.navList = navList
            },

            // 审批流相关
            selectedNode(state, val) {
                state.selectedNode = val
            },
            loadForm(state, val){
                state.design = val
            },
            setIsEdit(state, val){
                state.isEdit = val
            },
            // 审批流相关-end

            setUserInfo(state, val) {
                state.userInfo = val;
            },

            setWorkflow(state, val) {
                state.workflow = val;
            },

            setUserInfoDefer(state, val) {
                state.userInfoDefer = val;
            },

            setWorkflowDefer(state, val) {
                state.workflowDefer = val;
            },

            setPolicy(state, val) {
                state.policy = val;
            },

            setPolicyDefer(state, val) {
                state.policyDefer = val;
            },

            setBtnList(state, val) {
                state.btnList = val;
            },

        },
        actions: {
            updateNavList({commit}, route) { // 传入this.$route
                function flatten(arr) {
                    return [].concat(...arr.map(item => [].concat(item, ...flatten(item.menus || []))))
                }

                function setActive(dataList) {
                    let activeId = ''
                    dataList.forEach(value => {
                        if (value.metaName === route.meta.lastMenu) {
                            value.active = true
                            activeId = value.parentId
                        } else {
                            value.active = false
                        }
                    })
                    let getParentActive = function (parentId) {
                        if (parentId === 0) {
                            return
                        }
                        dataList.forEach(value => {
                            if (value.id === parentId) {
                                value.active = true
                                getParentActive(value.parentId)
                            }
                        })
                    }
                    getParentActive(activeId)
                    return dataList
                }

                function translateDataToTree(data) {
                    const parents = data.filter(value => value.parentId === '0' || value.parentId === 0 || value.parentId === undefined || value.parentId === null) // 没有父节点的数据
                    const children = data.filter(value => value.parentId !== '0' || value.parentId !== 0) // 有父节点的数据
                    // 定义转换方法的具体实现
                    const translator = (parents, children) => {
                        parents.forEach((parent) => {
                                children.forEach((current, index) => {
                                        if (current.parentId === parent.id) {
                                            const temp = JSON.parse(JSON.stringify(children))
                                            temp.splice(index, 1)
                                            translator([current], temp)
                                            typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
                                        }
                                    }
                                )
                            }
                        )
                    }
                    translator(parents, children)
                    return parents
                }

                return new Promise((resolve, reject) => {
                    get_consumer_menu_getUserMenu({clear_cache: true}).then(res => {
                        let oriNavList = res.datas
                        oriNavList = flatten(oriNavList) // 转成1维数组
                        for (let item of oriNavList) { // 删除menus
                            if (item.menus) {
                                delete item.menus
                            }
                        }
                        commit('setOriNavList', oriNavList) // 保存到原始菜单数组

                        let navList = JSON.parse(JSON.stringify(oriNavList))
                        navList = setActive(navList)
                        navList = translateDataToTree(navList)
                        commit('setNavList', navList)
                        resolve()
                    }).catch((error) => {
                        reject(error)
                    })
                })
            }
        }
    });

    // __store.commit(`init_app_config`);
    // __store.commit(`init_global_data`);
    // __store.commit(`init_current_user`);
    // __store.commit(`init_server_config`);
    __store.commit(`init_data_for_theme`);

    global.__store = __store;

    return __store;
};
init();

const update_last_refresh_time = function (pData) {
    __store.commit(`update_last_refresh_time`, pData);
};

const get_global_data = function () {
    return Q.when()
        .then(function () {
            return promise_init_global_data;
        })
        .then(function () {
            return __store.state.global_data;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

const get_current_user = function () {
    return Q.when()
        .then(function () {
            return promise_init_current_user;
        })
        .then(function () {
            return __store.state.current_user;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

const get_server_config = function () {
    return Q.when()
        .then(function () {
            return promise_init_server_config;
        })
        .then(function () {
            return __store.state.server_config;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

export default __store;
