const Q = require(`q`);

const get_user_info = function () {
    const data = {
        user_id: `mock-user-id`,
        user_name: `mock-user-id`,
        user_type: `mock-user-type`,
    };
    return Q.resolve(data);
};

module.exports = get_user_info;
