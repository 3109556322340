import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/train-reserve',
            name: 'admin-train-reserve',
            component: () => import(/* webpackChunkName: "page-admin-travel-book-train-train-reserve" */ `./1.2.0/index.vue`),
            meta: {
                lastMenu: 'travelOrder'
            },
        },
    ],
};
