import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/hotel-refund-detail',
            name: 'admin-hotel-refund-detail',
            component: () => import(/* webpackChunkName: "page-admin-myOrder-hotel-hotel-refund-detail" */ `./1.0.4/index.vue`),//备注：记得在订单中心那里修改对应的版本
            meta: {
                lastMenu: 'adminMyOrderHotel',
                hideTab: true,
                hotelMeta: '退订详情'
            },
        }
    ],
};
