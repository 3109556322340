import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/order-center/flight-order-list',
      name: 'admin-order-center-flight-order-list',
      component: () => import(/* webpackChunkName: "page-admin-order-center-flight-order-list" */ './1.0.2/index.vue'),
      meta: {
        lastMenu: 'orderCenterFlightOrderList',
        data_for_back_layout: {
          layoutType: 'orderCenterType',
          lastMenu: 'orderCenterFlightOrderList',
        }
      }
    }
  ]
}
