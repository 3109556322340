import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/order-center/train-change-details',
            name: 'admin-order-center-train-change-details',
            component: () => import(/* webpackChunkName: "page-admin-order-center-train-change-details" */ `@/page/admin/myOrder/trainOrder/trainOrderChangeDetail/1.0.4/index.vue`),
            meta: {
                lastMenu: 'orderCenterTrainChangeList',
                hideTab: true,
                data_for_back_layout: {
                    layoutType: 'orderCenterType',
                    lastMenu: 'orderCenterTrainChangeList',
                }
            },
        }
    ],
};
