import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/train-change-apply-result',
      name: 'admin-train-change-apply-result',
      component: () => import(/* webpackChunkName: "page-admin-myOrder-trainOrder-train-change-apply-result" */ `./1.0.0/index.vue`),
      meta: {
        lastMenu: 'adminMyOrderTrain',
        hideTab: true,
        data_for_back_layout: {
          layoutType: 'frontLayoutType'
        }
      },
    },

  ],
};
