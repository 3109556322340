import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/my-evection',
            name: 'admin-my-evection',
            component: () => import(/* webpackChunkName: "page-admin-myOrder-evection" */ `./index.vue`),
            meta: {
                lastMenu: 'adminMyEvection'
            },
        }
    ],
};
