import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/structure',
            name: 'admin-structure',
            component: () => import(/* webpackChunkName: "page-admin-company-management-structure-management-structure-list" */ `./index.vue`),
            meta: {
                lastMenu: 'structureList'
            },
        },
    ],
};
