import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/evection-detail',
            name: 'admin-evection-detail',
            component: () => import(/* webpackChunkName: "page-admin-myOrder-evection-detail" */ `./1.0.1/index.vue`),
            meta: {

            },
        }
    ],
};
