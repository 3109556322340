import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/approval/design',
            name: 'admin-approval-design',
            component: () => import(/* webpackChunkName: "page-admin-approval-process-form-process-design" */ `./index.vue`),
            meta: {
                data_for_back_layout: {
                    layoutType: 'fullLayoutType',
                },
                lastMenu: 'admin-approval-design'
            },
        }
    ],
};
