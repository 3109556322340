import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/evection-rule',
      name: 'admin-evection-rule',
      component: () => import(/* webpackChunkName: "page-admin-company-management-evectionManagement-evectionRule" */ `./1.0.7.2/index.vue`), // 1.0.7.1增加用车控制， 1.0.8增加火车票抢票控制， 1.0.8.1增加火车票抢票控制+用车控制
      meta: {
        lastMenu: 'evectionRule'
      },
    },
  ],
};
