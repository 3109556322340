import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/hotel-order-detail',
            name: 'admin-hotel-order-detail',
            component: () => import(/* webpackChunkName: "page-admin-myOrder-hotel-hotel-order-detail" */ `./1.0.7/index.vue`),//备注：记得在订单中心那里修改对应的版本
            meta: {
                lastMenu: 'adminMyOrderHotel',
                hideTab: true,
                hotelMeta: '订单详情',
                data_for_back_layout: {
                    layoutType: 'orderType',
                    lastMenu: 'adminMyOrderHotel',
                }
            },
        },
    ],
};
