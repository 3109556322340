//---引用第三方---------------------------------------------------------------------------------------------------------
import moment from 'moment'
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; //默认主题
import get_token from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_token';
import set_token from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/set_token';
import ECharts from 'vue-echarts'
// 如果你实在需要全量引入 ECharts 从而无需手动引入模块，只需要在代码中添加：
// import "echarts";
// 手动引入 ECharts 各模块来减小打包体积
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'

// vue virtual scroller
import "vue-virtual-scroller/dist/vue-virtual-scroller.css" // 引入它的 css
import VueVirtualScroller from "vue-virtual-scroller" // 引入它

//---引用本项目---------------------------------------------------------------------------------------------------------
import App from './App.vue';
import aRouter from './router';
import init_router from './router/init_router';
import store from './lib/store';

require('@/lib/utils/CustomUtil')

// import './common-style/theme/custom-theme-001/theme/index.css'; //自定义主题
// import './common-style/theme/custom-theme-001/1.1.0/theme/index.less'; //自定义主题
// import './common-style/theme/custom-theme-001/1.1.1/haolv/theme/index.less'; //自定义主题
import './common-style/theme/custom-theme-001/1.1.3/theme/index.css'; //自定义主题
// import './common-style/theme/custom-theme-001/1.1.1/quancheng/theme/index.less'; //自定义主题
import './common-style/common/index.less'; //全局样式入口

import vue_plugin_global_method from './lib/vue-plugin/global-method';
// import OverwriteElButton from './component/overwrite-element-ui/el-button';
// import OverwriteElDialog from './component/overwrite-element-ui/el-dialog';

import init_baidu_tongji from './lib/common-service/init_baidu_tongji';

import Ellipsis from '@/page/admin/approval-process/component/common/Ellipsis'
import WDialog from '@/page/admin/approval-process/component/common/WDialog'
import Tip from '@/page/admin/approval-process/component/common/Tip'

//---Vue.use()----------------------------------------------------------------------------------------------------------
Vue.config.productionTip = false;

// Vue.use(ElementUI);
Vue.use(ElementUI, {size: 'small'});
Vue.use(vue_plugin_global_method);
// Vue.use(OverwriteElButton);
// Vue.use(OverwriteElDialog);
Vue.use(VueVirtualScroller);




Vue.use(Ellipsis);
Vue.use(WDialog);
Vue.use(Tip);

const use_ECharts = function () {
    // 全局注册组件（也可以使用局部注册）
    Vue.component('v-chart', ECharts);
};
use_ECharts();

Vue.prototype.$moment = moment;

Vue.prototype.$isNotEmpty = function(obj){
    return (obj !== undefined && obj !== null && obj !== '' && obj !== 'null')
}

Vue.prototype.$getDefalut = function(obj, key, df){
    return (obj === undefined || key === undefined || !this.$isNotEmpty(obj[key])) ? df : obj[key];
}

Vue.prototype.$deepCopy = function (obj){return JSON.parse(JSON.stringify(obj))}

//---init---------------------------------------------------------------------------------------------------------------
//加快webpack热更新（潘磊找的方法）
//第二步：调用init_router方法
init_router();
init_baidu_tongji();

//----------------------------------------------------------------------------------------------------------------------
const aApp = new Vue({
    router: aRouter,
    store,
    render: h => h(App)
}).$mount('#app');
// global.aApp = aApp;
// global.aRouter = aRouter;
global.get_token = get_token;
global.set_token = set_token;
