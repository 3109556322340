import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import to_boolean from 'nllib/src/convert/to_boolean';

import get_user_info from '@/lib/common-service/default/get_user_info';

import aRouter from "@/router";
import all_routes_processor from "@/router/init_router/lib/all_routes_processor";
import check_token_processor from "@/router/init_router/lib/check_token_processor";
import global_query_processor from "@/router/init_router/lib/global_query_processor";
import redirect_to_login_page from '@/lib/common-service/redirect_to_login_page';

const init_route_guard = function () {
    const get_all_route_list = all_routes_processor.get_all_route_list;

    aRouter.beforeEach((to, from, next) => {
        try {

            //如果是在页面的created、mounted、activated中，直接进行路由跳转
            //跳转的路由必须传入该参数并且设置为false
            //否则，会报这样的错：
            //Error: Redirected when going from xxx to xxx via a navigation guard.
            const route_guard = to.params.route_guard || {};
            const enable_route_guard = to_boolean(route_guard.enable, true);
            if (!enable_route_guard) {
                next();
                return;
            }

            Q.when()
                .then(function () {
                    return check_token_processor.next({to, from, next});
                })
                .then(function (data) {
                    // return global_query_processor.next({to, from, next});

                    const check_token_type = data.check_token_type;
                    if (check_token_type === `001`) {
                        //const msg = `check_token_processor::to_name=${to_name}|enable_check_token=false，不需要执行check_token操作，check_token_type=001`;
                        next();
                        return;
                    }
                    if (check_token_type === `002`) {
                        //const msg = `check_token_processor::to_name=${to_name}|token为假值，不需要执行check_token操作，check_token_type=002`;
                        next();
                        return;
                    }
                    if (check_token_type === `101`) {
                        //const msg = `check_token_processor::to_name=${to_name}|check_token操作验证通过，check_token_type=101`;
                        return Q.when()
                            .then(function () {
                                return get_user_info();
                            })
                            .then(function (data_get_user_info) {
                                // const user_type = data_get_user_info.user_type;
                                // if (user_type === `mock-user-type`) {
                                //     next({
                                //         name: `admin-desktop`,
                                //         params: {
                                //             route_guard: {
                                //                 enable: false,
                                //             },
                                //         },
                                //     });
                                //     return;
                                // }

                                next({
                                    params: {
                                        route_guard: {
                                            enable: false,
                                        },
                                    },
                                });

                            })
                            ;
                    }
                    if (check_token_type === `102`) {
                        //const msg = `check_token_processor::to_name=${to_name}|check_token操作验证不通过，check_token_type=102`;
                        redirect_to_login_page();
                        return;
                    }
                    if (check_token_type === `901`) {
                        //const msg = `check_token_processor::to_name=${to_name}|check_token操作验证不通过，check_token_type=901`;
                        redirect_to_login_page();
                        return;
                    }

                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })

        } catch (ex) {
            console.error(ex);
        }
    });
};
export default init_route_guard;
