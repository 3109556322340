import import_latest_version_module from '@/component/back-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/haolv-monitor',
            name: 'admin-haolv-monitor',
            component: () => import(/* webpackChunkName: "page-admin-report-form-haolv-monitor" */ `./index.vue`),
            meta: {
                lastMenu: 'haolvMonitor',
            },
        },
    ],
};
