//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "WDialog",
  install(Vue) {
    Vue.component("WDialog", this);
  },
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "50%",
    },
    value: {
      type: Boolean,
      default: false,
    },
    clickClose: {
      type: Boolean,
      default: false,
    },
    closeFree: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: "取 消",
    },
    okText: {
      type: String,
      default: "确 定",
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
